import React from 'react'

import { useStaticQuery, graphql, Link } from 'gatsby'

import Layout from '../components/Layout'
import { getCommissionedProjects } from './__generated__/getCommissionedProjects'
import CollectionToggler from '../components/CollectionToggler'
import { StyledGallery } from './personal'
import SEO from '../components/SEO'

export const projectGalleryQuery = graphql`
    fragment ProjectGalleryData on MdxConnection {
        edges {
            node {
                id
                fields {
                    path
                }
                frontmatter {
                    images {
                        featured
                        image {
                            id
                            childImageSharp {
                                fluid(maxWidth: 400, quality: 90) {
                                    aspectRatio
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

export default function Commissioned({ location }) {
    const data: getCommissionedProjects = useStaticQuery(graphql`
        query getCommissionedProjects {
            allCommissionedProjects: allMdx(
                filter: {
                    fields: { category: { eq: "commissioned" } }
                    frontmatter: {
                        images: { elemMatch: { featured: { eq: true } } }
                    }
                }
            ) {
                ...ProjectGalleryData
            }
        }
    `)
    const images = data.allCommissionedProjects.edges.flatMap((p) =>
        p.node.frontmatter.images
            .filter((i) => i.featured)
            .map((i) => ({
                id: i.image.id,
                childImageSharp: i.image.childImageSharp,
                path: p.node.fields.path,
            }))
    )
    return (
        <Layout footer={<CollectionToggler location={location} />}>
            <SEO
                title="Commissioned"
                description="Eine ausgewählte Auswahl an Aufträgen"
                image={images[0] && images[0].childImageSharp.fluid.src}
            />
            <StyledGallery
                images={images}
                navigateToExtractor={(image) => `/${image.path}/stream`}
                navigationAnimConfig={{
                    swipe: true,
                    direction: 'up',
                    duration: 1,
                }}
            />
        </Layout>
    )
}
